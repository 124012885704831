import {
  SET_DASHBOARD,
  SET_DASHBOARD_INVALID_BRANCH,
  TOTAL_TICKETS,
  TOTAL_SERVE,
  TOTAL_CANCEL,
  TOTAL_NOSHOW,
  TOTAL_NOTPROCESS,
  TICKET_PER_SERVICE,
  TICKET_PER_BRANCH,
  TICKET_PER_AGENT,
  SERVICE_AVG_RATING,
  BRANCH_AVG_RATING,
  BRANCH_PERFORMANCE,
  TICKET_PER_DAY,
  AVG_CUSTOMER_RATING,
  TOTAL_REVIEW,
  MOST_USE_SERVICE,
  AVG_TICKET_PER_AGENT,
  AGENT_AVG_RATING,
  GRAND_AVG_PER_AGENT,
  GRAND_AVG_PER_BRANCH,
  IS_AVG_SERVING_TIME_SHOW,
  AVG_SERVE_TIME,
  TICKETS_BY_ORIGIN,
  REBOOKTICKETS,
  TOTAL_APPOINTMENT,
  WITHOUT_DELETE_BRANCH,
  APPOINTMENT_SCHEDULED,
  APPOINTMENT_PROCESSING,
  APPOINTMENT_COMPLETED,
  APPOINTMENT_CANCELLEDTICKET,
  APPOINTMENT_NOSHOW,
  APPOINTMENT_NOPROCESS,
  APPOINTMENT_PER_DAY,
  APPOINTMENT_LOADING,
  ESERVICE_NUMBEROFREQUESTS,
  ESERVICE_SUBMITTED,
  ESERVICE_PROCESSING,
  ESERVICE_COMPLETED,
  ESERVICE_CANCELLED,
  ESERVICE_LOADING,
  SET_ESERVICE_TRAIL,
  SET_ESERVICE_LOADER,
  APPOINTMENT_PEAK_HOURS,
  DONATION_COMPLETED,
  DONATION_NUMBEROFREQUESTS,
  DONATION_PROCESSING,
  DONATION_SUBMITTED,
  DONATION_CANCELLED,
  DONATION_ENGAGEMENT,
} from "../../actions/types";

const initState = {
  dashboard: {
    totalTicket: 0,
    ticketServed: 0,
    cancelledTicket: 0,
    noShowTicket: 0,
    totalNotProcess: 0,
    ticketPerService: [],
    ticketPerBranch: [],
    ticketsPerAgent: [],
    avgFeedbackperService: [],
    avgFeedbackBranch: [],
    avgFeedbackPerAgent: [],
    branchPerformance: [],
    AgentPerformance: [],
    ticketPerWeek: [],
    avgFeedbackMerchant: 0,
    ticketReview: 0,
    mostUseService: [],
    avgTicketPerAgent: 0,
    grandAvgPerBranch: 0,
    grandAvgPerAgent: 0,

    isAvgServingTimeShow: false,
    avgServingTime: "",
    ticketsByOrigin: [],
    rebookTickets: 0,
    withoutDeleteBranch : []
  },
  appointmentDashboard: {
    totalAppointments: 0,
    scheduled: 0,
    processing: 0,
    completed: 0,
    cancelledTicket: 0,
    noShowTicket: 0,
    totalNotProcess: 0,
    appointmentsLoading:false,
    appointmentsPerDay: [],
    appointmentsPeakHours:[] 
  },
  eServiceDashboard: {
    numberOfRequests: 0,
    submitted: 0,
    processing: 0,
    completed: 0,
    cancelledEservice: 0,
    eServiceLoading: false,
    TicketTrailLoader:false,
    eServiceTicketTrail:[]
  },
  donationDashboard: {
    numberOfRequests: 0,
    submitted: 0,
    processing: 0,
    completed: 0,
    cancelledEservice: 0,
    engagementData:{}
  },
  isinValidBranch: false,
};

const dashboard = (state = initState, action) => {
  switch (action.type) {
    case SET_DASHBOARD: {
      return { ...state, dashboard: action.payload };
    }
    case TOTAL_TICKETS: {
      return {
        ...state,
        dashboard: { ...state.dashboard, totalTicket: action.payload },
      };
    }
    case TOTAL_SERVE: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ticketServed: action.payload },
      };
    }
    case TOTAL_CANCEL: {
      return {
        ...state,
        dashboard: { ...state.dashboard, cancelledTicket: action.payload },
      };
    }
    case TOTAL_NOSHOW: {
      return {
        ...state,
        dashboard: { ...state.dashboard, noShowTicket: action.payload },
      };
    }
    case TOTAL_NOTPROCESS: {
      return {
        ...state,
        dashboard: { ...state.dashboard, totalNotProcess: action.payload },
      };
    }
    case TICKET_PER_SERVICE: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ticketPerService: action.payload },
      };
    }
    case TICKET_PER_BRANCH: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ticketPerBranch: action.payload },
      };
    }
    case TICKET_PER_AGENT: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ticketsPerAgent: action.payload },
      };
    }
    case SERVICE_AVG_RATING: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgFeedbackperService: action.payload,
        },
      };
    }
    case BRANCH_AVG_RATING: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgFeedbackBranch: action.payload,
        },
      };
    }
    case BRANCH_PERFORMANCE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          branchPerformance: action.payload,
        },
      };
    }
    case TICKET_PER_DAY: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ticketPerWeek: action.payload,
        },
      };
    }
    case AVG_CUSTOMER_RATING: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgFeedbackMerchant: action.payload,
        },
      };
    }
    case TOTAL_REVIEW: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ticketReview: action.payload,
        },
      };
    }
    case MOST_USE_SERVICE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          mostUseService: action.payload,
        },
      };
    }
    case AVG_TICKET_PER_AGENT: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgTicketPerAgent: action.payload,
        },
      };
    }
    case AGENT_AVG_RATING: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgFeedbackPerAgent: action.payload,
        },
      };
    }
    case GRAND_AVG_PER_AGENT: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          grandAvgPerAgent: action.payload,
        },
      };
    }
    case GRAND_AVG_PER_BRANCH: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          grandAvgPerBranch: action.payload,
        },
      };
    }
    case IS_AVG_SERVING_TIME_SHOW: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isAvgServingTimeShow: action.payload,
        },
      };
    }
    case AVG_SERVE_TIME: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          avgServingTime: action.payload,
        },
      };
    }
    case SET_DASHBOARD_INVALID_BRANCH: {
      return { ...state, isinValidBranch: action.payload };
    }
    case TICKETS_BY_ORIGIN: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ticketsByOrigin: action.payload },
      };
    }
    case WITHOUT_DELETE_BRANCH: {
      return {
        ...state,
        dashboard: { ...state.dashboard, withoutDeleteBranch: action.payload },
      };
    }
    case REBOOKTICKETS: {
      return {
        ...state,
        dashboard: { ...state.dashboard, rebookTickets: action.payload },
      };
    }

    case TOTAL_APPOINTMENT: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          totalAppointments: action.payload,
        },
      };
    }
    case APPOINTMENT_SCHEDULED: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          scheduled: action.payload,
        },
      };
    }
    case APPOINTMENT_PROCESSING: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          processing: action.payload,
        },
      };
    }
    case APPOINTMENT_COMPLETED: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          completed: action.payload,
        },
      };
    }
    case APPOINTMENT_CANCELLEDTICKET: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          cancelledTicket: action.payload,
        },
      };
    }
    case APPOINTMENT_NOSHOW: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          noShowTicket: action.payload,
        },
      };
    }
    case APPOINTMENT_NOPROCESS: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          totalNotProcess: action.payload,
        },
      };
    }
    case APPOINTMENT_PER_DAY: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          appointmentsPerDay: action.payload,
        },
      };
    }
    case APPOINTMENT_PEAK_HOURS: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          appointmentsPeakHours: action.payload,
        },
      };
    }
    case APPOINTMENT_LOADING: {
      return {
        ...state,
        appointmentDashboard: {
          ...state.appointmentDashboard,
          appointmentsLoading: action.payload,
        },
      };
    }
    case ESERVICE_COMPLETED: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          completed: action.payload,
        },
      };
    }
    case ESERVICE_NUMBEROFREQUESTS: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          numberOfRequests: action.payload,
        },
      };
    }
    case ESERVICE_PROCESSING: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          processing: action.payload,
        },
      };
    }
    case ESERVICE_SUBMITTED: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          submitted: action.payload,
        },
      };
    }
    case ESERVICE_CANCELLED: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          cancelledEservice: action.payload,
        },
      };
    }
    case ESERVICE_LOADING: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          eServiceLoading: action.payload,
        },
      };
    }
    case SET_ESERVICE_TRAIL: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          eServiceTicketTrail: action.payload,
        },
      };
    }
    case SET_ESERVICE_LOADER: {
      return {
        ...state,
        eServiceDashboard: {
          ...state.eServiceDashboard,
          TicketTrailLoader: action.payload,
        },
      };
    }case DONATION_COMPLETED: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          completed: action.payload,
        },
      };
    }
    case DONATION_NUMBEROFREQUESTS: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          numberOfRequests: action.payload,
        },
      };
    }
    case DONATION_PROCESSING: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          processing: action.payload,
        },
      };
    }
    case DONATION_SUBMITTED: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          submitted: action.payload,
        },
      };
    }
    case DONATION_CANCELLED: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          cancelledEservice: action.payload,
        },
      };
    }
    case DONATION_ENGAGEMENT: {
      return {
        ...state,
        donationDashboard: {
          ...state.donationDashboard,
          engagementData: action.payload,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default dashboard;
