import React from "react";
import i18n from "../i18n/i18n";
const navigationConfig = [
  {
    id: "dashboard",
    title: i18n.t("COMMON.dashboard"),
    type: "collapse",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <g
          id="Home"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-4"
            transform="translate(2.000000, 2.000000)"
            fill="#606C74"
          >
            <path
              d="M18,18 L17.5,18 L14,18 L14,13.5 C14,11.846 12.654,10.5 11,10.5 L9,10.5 C7.346,10.5 6,11.846 6,13.5 L6,18 L3,18 L2,18 L2,8.454 L10,2.494 L18,8.454 L18,18 Z M8,18 L8,13.5 C8,12.948 8.447,12.5 9,12.5 L11,12.5 C11.553,12.5 12,12.948 12,13.5 L12,18 L8,18 Z M19.598,7.15 L10.598,0.445 C10.42,0.313 10.21,0.247 10,0.247 C9.79,0.247 9.58,0.313 9.402,0.445 L0.402,7.15 C0.149,7.339 0,7.636 0,7.952 L0,20 L3,20 L8,20 L12,20 L17.5,20 L20,20 L20,7.952 C20,7.636 19.851,7.339 19.598,7.15 L19.598,7.15 Z"
              id="Fill-2"
            ></path>
          </g>
        </g>
      </svg>
    ),
    navLink: "/",
    children: [
      {
        id: "ticketDashboard",
        title: i18n.t("COMMON.ticketDashboard"),
        permission: true,
        type: "item",
        navLink: "/",
      },
      {
        id: "appointmentDashboard",
        title: i18n.t("COMMON.appointmentDashboard"),
        type: "item",
        permission: false,
        navLink: "/appointmentDashboard",
      },
      {
        id: "eserviceDashboard",
        title: i18n.t("COMMON.eserviceDashboard"),
        type: "item",
        permission: false,
        navLink: "/eserviceDashboard",
      },
      {
        id: "donationDashboard",
        title: i18n.t("COMMON.donationDashboard"),
        type: "item",
        permission: false,
        navLink: "/donationDashboard",
      },
    ],
  },

  {
    id: "livefeed",
    title: i18n.t("COMMON.livefeed"),
    type: "item",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
        <g id="signal-01">
          <path id="Icon" d="M16.2426 7.75738C18.5858 10.1005 18.5858 13.8995 16.2426 16.2427M7.75736 16.2426C5.41421 13.8995 5.41421 10.1005 7.75736 7.75735M4.92893 19.0711C1.02369 15.1658 1.02369 8.8342 4.92893 4.92896M19.0711 4.929C22.9763 8.83424 22.9763 15.1659 19.0711 19.0711M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8955 10.8954 10 12 10C13.1046 10 14 10.8955 14 12Z" stroke="#606C74" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" fill="#ffffff" />
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/livefeed",
  },
  {
    id: "categories",
    title: i18n.t("COMMON.categories"),
    type: "item",
    icon: (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12.25C11.56 12.25 11.12 12.14 10.72 11.93L4.21 8.42001C3.62 8.10001 3.25 7.47001 3.25 6.77001C3.25 6.07001 3.62 5.44001 4.21 5.12001L10.72 1.61001C11.52 1.18001 12.48 1.18001 13.27 1.61001L19.78 5.12001C20.37 5.44001 20.74 6.07001 20.74 6.77001C20.74 7.47001 20.37 8.10001 19.78 8.42001L13.27 11.93C12.88 12.15 12.44 12.25 12 12.25ZM12 2.78001C11.81 2.78001 11.61 2.83001 11.43 2.93001L4.93 6.43001C4.76 6.52001 4.75 6.71001 4.75 6.77001C4.75 6.83001 4.76 7.02001 4.93 7.10001L11.44 10.61C11.8 10.8 12.21 10.8 12.57 10.61L19.08 7.10001C19.24 7.01001 19.26 6.82001 19.26 6.77001C19.26 6.71001 19.25 6.52001 19.08 6.44001L12.57 2.93001C12.39 2.83001 12.19 2.78001 12 2.78001Z"
          transform="translate(2.000000, 2.000000)"
          fill="#606C74"
        />
        <path
          d="M9.78 22.72C9.5 22.72 9.21 22.65 8.95 22.52L2.89 19.5C1.88 18.99 1.25 17.97 1.25 16.84V11.12C1.25 10.47 1.58 9.88 2.13 9.54C2.69 9.2 3.36 9.17 3.94 9.46L10 12.48C11.01 12.99 11.64 14 11.64 15.14V20.86C11.64 21.51 11.31 22.1 10.76 22.44C10.46 22.63 10.12 22.72 9.78 22.72ZM3.11 10.76C3.03 10.76 2.96 10.79 2.92 10.81C2.86 10.85 2.75 10.94 2.75 11.12V16.84C2.75 17.4 3.06 17.91 3.56 18.16L9.61 21.19C9.77 21.27 9.9 21.21 9.96 21.18C10.02 21.14 10.13 21.05 10.13 20.87V15.15C10.13 14.59 9.82 14.08 9.32 13.83L3.27 10.8C3.21 10.77 3.16 10.76 3.11 10.76Z"
          transform="translate(2.000000, 2.000000)"
          fill="#606C74"
        />
        <path
          d="M14.2201 22.72C13.8801 22.72 13.5401 22.63 13.2401 22.44C12.6901 22.1 12.3601 21.51 12.3601 20.86V15.14C12.3601 14.01 12.9901 12.99 14.0001 12.48L20.0501 9.45C20.6301 9.16 21.3101 9.19 21.8601 9.53C22.4101 9.87 22.7401 10.46 22.7401 11.11V16.83C22.7401 17.96 22.1101 18.98 21.1001 19.49L15.0501 22.52C14.7901 22.66 14.5001 22.72 14.2201 22.72ZM20.8901 10.76C20.8401 10.76 20.7901 10.77 20.7301 10.8L14.6801 13.83C14.1801 14.08 13.8701 14.58 13.8701 15.15V20.87C13.8701 21.05 13.9801 21.14 14.0401 21.18C14.1001 21.22 14.2301 21.27 14.3901 21.19L20.4401 18.16C20.9401 17.91 21.2501 17.4 21.2501 16.84V11.12C21.2501 10.94 21.1401 10.85 21.0801 10.81C21.0401 10.79 20.9701 10.76 20.8901 10.76Z"
          transform="translate(2.000000, 2.000000)"
          fill="#606C74"
        />
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/category",
  },
  {
    id: "services",
    title: i18n.t("COMMON.services"),
    type: "item",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Service/Merchant"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-4"
            transform="translate(2.000000, 3.000000)"
            fill="#606C74"
          >
            <path
              d="M2.00035,15.99695 L2.00035,10.60745 C4.59835,11.49045 7.28035,11.94495 10.00035,11.94495 C12.72385,11.94495 15.40585,11.48945 18.00035,10.60395 L18.00035,15.99695 L2.00035,15.99695 Z M7.28435,5.49695 L12.71635,5.49695 L18.00035,5.49695 L18.00035,8.48845 C15.41385,9.45545 12.72835,9.94495 10.00035,9.94495 C7.27685,9.94495 4.59135,9.45645 2.00035,8.49295 L2.00035,5.49695 L7.28435,5.49695 Z M8.00035,3.49695 L12.00035,3.49695 L12.00035,1.99695 L8.00035,1.99695 L8.00035,3.49695 Z M18.80985,3.49695 L14.00035,3.49695 L14.00035,1.28095 C14.00035,0.57195 13.42535,-0.00305 12.71635,-0.00305 L7.28435,-0.00305 C6.57535,-0.00305 6.00035,0.57195 6.00035,1.28095 L6.00035,3.49695 L1.19085,3.49695 C0.53335,3.49695 0.00035,4.02995 0.00035,4.68745 L0.00035,16.80645 C0.00035,17.46395 0.53335,17.99695 1.19085,17.99695 L18.80985,17.99695 C19.46735,17.99695 20.00035,17.46395 20.00035,16.80645 L20.00035,4.68745 C20.00035,4.02995 19.46735,3.49695 18.80985,3.49695 L18.80985,3.49695 Z"
              id="Fill-2"
            ></path>
          </g>
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/services",
  },

  {
    id: "queues",
    title: i18n.t("COMMON.queues"),
    type: "item",
    icon: (
      <svg
        id="_x31__x2C_5"
        enableBackground="new 0 0 24 24"
        height="20"
        viewBox="0 0 25 25"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        fill="#606C74"
      >
        <path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
        <path d="m19.25 20c-.151 0-.303-.045-.436-.14l-1.75-1.25c-.197-.14-.314-.368-.314-.61v-3.25c0-.414.336-.75.75-.75s.75.336.75.75v2.864l1.436 1.025c.337.241.415.709.174 1.046-.146.206-.377.315-.61.315z" />
        <path d="m7 5c-.716 0-7-.07-7-2.5s6.284-2.5 7-2.5 7 .07 7 2.5-6.284 2.5-7 2.5zm-5.436-2.5c.471.401 2.447 1 5.436 1s4.965-.599 5.436-1c-.471-.401-2.447-1-5.436-1s-4.965.599-5.436 1zm10.957.094h.01z" />
        <path d="m7 9c-.716 0-7-.07-7-2.5 0-.414.336-.75.75-.75s.75.336.75.75c.218.299 2.268 1 5.5 1s5.282-.701 5.521-1.094c0-.415.325-.703.74-.703.413 0 .739.383.739.797 0 2.43-6.284 2.5-7 2.5z" />
        <path d="m7 13c-.716 0-7-.07-7-2.5 0-.414.336-.75.75-.75.391 0 .713.3.747.682.305.396 2.334 1.068 5.503 1.068 1.032 0 2.047-.073 2.935-.211.409-.061.793.217.856.626s-.216.792-.626.856c-.962.15-2.057.229-3.165.229zm-5.5-2.5h.01z" />
        <path d="m13.25 10c-.414 0-.75-.336-.75-.75v-6.75c0-.414.336-.75.75-.75s.75.336.75.75v6.75c0 .414-.336.75-.75.75z" />
        <path d="m7 17c-.716 0-7-.07-7-2.5v-12c0-.414.336-.75.75-.75s.75.336.75.75v12c.217.3 2.265 1 5.5 1 .442 0 .875-.009 1.287-.038.402-.037.771.282.801.695s-.282.772-.695.801c-.447.032-.915.042-1.393.042z" />
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/queues",
  },
  {
    id: "users",
    title: i18n.t("COMMON.users"),
    type: "item",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Profile"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-7"
            transform="translate(2.000000, 2.000000)"
            fill="#606C74"
          >
            <g id="Group-6" transform="translate(0.000000, -0.000400)">
              <path
                d="M9.9996,0.0009 C7.2386,0.0009 5.0006,2.2389 5.0006,5.0009 C5.0006,7.7619 7.2386,10.0009 9.9996,10.0009 C12.7616,10.0009 14.9996,7.7619 14.9996,5.0009 C14.9996,2.2389 12.7616,0.0009 9.9996,0.0009 M9.9996,2.0009 C11.6536,2.0009 12.9996,3.3469 12.9996,5.0009 C12.9996,6.6549 11.6536,8.0009 9.9996,8.0009 C8.3466,8.0009 7.0006,6.6549 7.0006,5.0009 C7.0006,3.3469 8.3466,2.0009 9.9996,2.0009"
                id="Fill-2"
              ></path>
              <path
                d="M12.5006,12.0009 L7.5006,12.0009 C3.3576,12.0009 0.0006,15.3579 0.0006,19.4999 L0.0006,19.9999 L20.0006,19.9999 L20.0006,19.4999 C20.0006,15.3579 16.6426,12.0009 12.5006,12.0009 M12.5006,14.0009 C15.0136,14.0009 17.1386,15.6939 17.7926,17.9999 L2.2086,17.9999 C2.8626,15.6939 4.9876,14.0009 7.5006,14.0009 L12.5006,14.0009"
                id="Fill-4"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/users",
  },
  {
    id: "branches",
    title: i18n.t("COMMON.branches"),
    type: "item",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Map"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-7"
            transform="translate(4.000000, 1.000000)"
            fill="#606C74"
          >
            <g id="Group-6" transform="translate(0.000000, 0.500450)">
              <path
                d="M8.00005,0.49545 C7.77555,0.49545 7.55305,0.50345 7.33305,0.51845 C0.48705,0.99395 -2.45295,9.76245 2.39905,14.74495 L8.00005,20.49595 L13.60055,14.74495 C18.45205,9.76245 15.51255,0.99395 8.66655,0.51845 C8.44655,0.50345 8.22355,0.49545 8.00005,0.49545 M8.00005,2.49545 C8.17605,2.49545 8.35355,2.50195 8.52805,2.51395 C10.81055,2.67245 12.68705,4.11245 13.54755,6.36645 C14.28505,8.29945 14.31055,11.14945 12.16805,13.34945 L8.00005,17.62945 L3.83155,13.34945 C1.68905,11.14945 1.71405,8.29945 2.45205,6.36645 C3.31255,4.11245 5.18905,2.67245 7.47105,2.51395 C7.64605,2.50195 7.82355,2.49545 8.00005,2.49545"
                id="Fill-2"
              ></path>
              <path
                d="M8.00005,4.4959 C5.79105,4.4959 4.00005,6.2869 4.00005,8.4959 C4.00005,10.7049 5.79105,12.4959 8.00005,12.4959 C10.20905,12.4959 12.00005,10.7049 12.00005,8.4959 C12.00005,6.2869 10.20905,4.4959 8.00005,4.4959 M8.00005,6.4959 C9.10255,6.4959 10.00005,7.3929 10.00005,8.4959 C10.00005,9.5989 9.10255,10.4959 8.00005,10.4959 C6.89755,10.4959 6.00005,9.5989 6.00005,8.4959 C6.00005,7.3929 6.89755,6.4959 8.00005,6.4959"
                id="Fill-4"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    ),
    navLink: "/branches",
  },
  {
    id: "reports",
    title: i18n.t("COMMON.reports"),
    type: "item",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-book-open"
      >
        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
      </svg>
    ),
    navLink: "/reports",
  },

  {
    id: "feedback",
    title: i18n.t("COMMON.feedback"),
    type: "item",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-message-square"
      >
        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/feedback",
  },
  // {
  //   id: "logs",
  //   title: i18n.t("COMMON.logs"),
  //   type: "item",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20"
  //       height="20"
  //       viewBox="0 0 25 25"
  //       fill="none"
  //       stroke="currentColor"
  //       strokeWidth="2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       className="feather feather-square"
  //     >
  //       <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
  //     </svg>
  //   ),
  //   permissions: ["admin", "editor"],
  //   navLink: "/logs",
  // },
  {
    id: "logs",
    title: i18n.t("COMMON.logs"),
    type: "collapse",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-square"
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
      </svg>
    ),
    navLink: "/logs",
    children: [
      {
        id: "merchantUsersLogs",
        title: i18n.t("COMMON.merchantUsersLogs"),
        permission: true,
        type: "item",
        navLink: "/logs",
      },

      {
        id: "instantTicketsLogs",
        title: i18n.t("COMMON.instantTicketsLogs"),
        type: "item",
        permission: true,
        navLink: "/ticketLogs",
      },
      {
        id: "appointmentsLogs",
        title: i18n.t("COMMON.appointmentsLogs"),
        type: "item",
        permission: false,
        navLink: "/appointmentsLogs",
      },
      {
        id: "eservicesLogs",
        title: i18n.t("COMMON.eservicesLogs"),
        type: "item",
        permission: false,
        navLink: "/eservicesLogs",
      },
      {
        id: "donationLogs",
        title: i18n.t("COMMON.donationLogs"),
        type: "item",
        permission: false,
        navLink: "/donationLogs",
      },
    ],
  },
  {
    id: "workProfiles",
    title: i18n.t("COMMON.workProfiles"),
    type: "item",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-clock"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
    ),
    navLink: "/work-profile",
  },

  // {
  //   id: "events",
  //   title: i18n.t("COMMON.events"),
  //   type: "item",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24px"
  //       height="24px"
  //       viewBox="0 0 24 24"
  //       version="1.1"
  //     >
  //       <g fill="#606C74" id="surface1">
  //         <path d="M 6.113281 2.101562 C 5.851562 2.210938 5.625 2.738281 5.625 3.226562 C 5.625 3.976562 5.4375 4.125 4.539062 4.125 C 2.363281 4.125 2.25 4.539062 2.25 12.976562 C 2.25 17.585938 2.398438 20.960938 2.625 21.414062 C 3 22.085938 3.523438 22.125 12 22.125 C 20.476562 22.125 21 22.085938 21.375 21.414062 C 21.601562 20.960938 21.75 17.585938 21.75 12.976562 C 21.75 4.539062 21.636719 4.125 19.460938 4.125 C 18.5625 4.125 18.375 3.976562 18.375 3.1875 C 18.375 2.511719 18.113281 2.210938 17.476562 2.023438 C 15.9375 1.648438 15.1875 1.988281 15.074219 3.113281 C 14.960938 4.125 14.925781 4.125 12 4.125 C 9.074219 4.125 9.039062 4.125 8.925781 3.074219 C 8.851562 2.25 8.625 2.0625 7.6875 1.988281 C 7.085938 1.949219 6.335938 1.988281 6.113281 2.101562 Z M 8.25 4.3125 C 8.25 5.363281 8.101562 5.625 7.5 5.625 C 6.898438 5.625 6.75 5.363281 6.75 4.3125 C 6.75 3.261719 6.898438 3 7.5 3 C 8.101562 3 8.25 3.261719 8.25 4.3125 Z M 17.25 4.3125 C 17.25 5.363281 17.101562 5.625 16.5 5.625 C 15.898438 5.625 15.75 5.363281 15.75 4.3125 C 15.75 3.261719 15.898438 3 16.5 3 C 17.101562 3 17.25 3.261719 17.25 4.3125 Z M 5.925781 6 C 6.601562 7.085938 9 6.898438 9 5.773438 C 9 5.363281 9.675781 5.25 12 5.25 C 14.324219 5.25 15 5.363281 15 5.773438 C 15 6.898438 17.398438 7.085938 18.074219 6 C 18.789062 4.875 20.550781 4.988281 20.851562 6.148438 C 20.960938 6.636719 21 7.460938 20.925781 7.914062 L 20.8125 8.8125 L 3.1875 8.8125 L 3.074219 7.914062 C 3 7.460938 3.039062 6.636719 3.148438 6.148438 C 3.449219 4.988281 5.210938 4.875 5.925781 6 Z M 20.925781 14.925781 C 20.960938 17.925781 20.851562 20.175781 20.625 20.625 C 20.25 21.335938 19.835938 21.375 12 21.375 C 4.238281 21.375 3.75 21.335938 3.375 20.664062 C 2.960938 19.835938 2.851562 10.386719 3.261719 9.976562 C 3.414062 9.863281 7.386719 9.789062 12.148438 9.824219 L 20.8125 9.9375 Z M 20.925781 14.925781 " />
  //         <path d="M 10.875 12.375 C 10.5 13.273438 10.125 13.539062 8.960938 13.726562 C 7.273438 13.988281 7.164062 14.398438 8.324219 15.636719 C 8.960938 16.3125 9.113281 16.914062 9.039062 18 C 9 18.824219 9.039062 19.5 9.148438 19.5 C 9.261719 19.5 9.9375 19.238281 10.613281 18.898438 C 11.773438 18.335938 11.960938 18.335938 13.050781 18.898438 C 14.664062 19.726562 14.738281 19.6875 14.476562 17.851562 C 14.25 16.386719 14.324219 16.164062 15.1875 15.601562 C 16.539062 14.699219 16.351562 14.023438 14.664062 13.726562 C 13.5 13.539062 13.125 13.273438 12.75 12.375 C 12.488281 11.773438 12.074219 11.25 11.8125 11.25 C 11.550781 11.25 11.136719 11.773438 10.875 12.375 Z M 13.761719 14.625 L 14.738281 14.625 L 13.949219 15.488281 C 13.425781 16.050781 13.238281 16.613281 13.386719 17.175781 C 13.574219 17.960938 13.539062 18 12.675781 17.585938 C 11.960938 17.289062 11.511719 17.289062 10.914062 17.585938 C 10.199219 18 10.125 17.925781 10.125 17.023438 C 10.125 16.460938 9.863281 15.675781 9.488281 15.300781 C 8.925781 14.664062 8.925781 14.625 9.824219 14.625 C 10.460938 14.625 10.914062 14.324219 11.210938 13.613281 L 11.699219 12.5625 L 12.226562 13.613281 C 12.601562 14.324219 13.050781 14.625 13.761719 14.625 Z M 13.761719 14.625 " />
  //       </g>
  //     </svg>
  //   ),
  //   navLink: "/events",
  // },
  {
    id: "forms",
    title: i18n.t("COMMON.forms"),
    type: "item",
    icon: (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_2)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.02969 0.00256348C0.909214 0.00256348 0.000732422 0.911045 0.000732422 2.03202V17.9732C0.000732422 19.0942 0.909714 20.0021 2.02969 20.0021H17.9714C19.0918 20.0021 19.9998 19.0942 19.9998 17.9732V2.03152C19.9998 0.911045 19.0918 0.00256348 17.9714 0.00256348H2.02969ZM2.02969 2.00252H17.9714C17.9874 2.00252 17.9999 2.01552 17.9999 2.03152V17.9732C17.9999 17.9897 17.9874 18.0022 17.9714 18.0022H2.02969C2.01419 18.0022 2.00069 17.9897 2.00069 17.9732V2.03202C2.00069 2.01552 2.01369 2.00252 2.02969 2.00252Z"
            fill="#606C74"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.00635 7.00142H10.0062V5.00146H4.00635V7.00142Z"
            fill="#606C74"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.00635 11.0013H16.0056V9.00134H4.00635V11.0013Z"
            fill="#606C74"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.00684 15.0012H14.0066V13.0012H4.00684V15.0012Z"
            fill="#606C74"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="20" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    navLink: "/forms",
  },
  {
    id: "settings",
    title: i18n.t("COMMON.settings"),
    type: "item",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Settings</title>
        <g
          id="Settings"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-5"
            transform="translate(3.000000, 2.000000)"
            fill="#606C74"
          >
            <path
              d="M8.9997,0.0004 L0.4997,5.0004 L0.4997,15.0004 L8.9997,20.0004 L17.4997,15.0004 L17.4997,5.0004 L8.9997,0.0004 Z M8.9997,2.3204 L15.4997,6.1444 L15.4997,13.8554 L8.9997,17.6804 L2.4997,13.8554 L2.4997,6.1444 L8.9997,2.3204 Z"
              id="Fill-2"
            ></path>
            <path
              d="M8.9997,5.5004 C6.5147,5.5004 4.4997,7.5154 4.4997,10.0004 C4.4997,12.4854 6.5147,14.5004 8.9997,14.5004 C11.4847,14.5004 13.4997,12.4854 13.4997,10.0004 C13.4997,7.5154 11.4847,5.5004 8.9997,5.5004 M8.9997,7.5004 C10.3787,7.5004 11.4997,8.6214 11.4997,10.0004 C11.4997,11.3784 10.3787,12.5004 8.9997,12.5004 C7.6217,12.5004 6.4997,11.3784 6.4997,10.0004 C6.4997,8.6214 7.6217,7.5004 8.9997,7.5004"
              id="Fill-3"
            ></path>
          </g>
        </g>
      </svg>
    ),
    permissions: ["admin", "editor"],
    navLink: "/setting",
  },
];

export default navigationConfig;
